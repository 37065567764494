.bg {
    width: 100vw;
    height: 100vh;
    background: url(../../assets/bg2.jpg) no-repeat;
    background-size:100% 100%;
}
.login {
    color: #fff !important;
    width: 500px;
    padding: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    h1 {
        text-align: center;
        color: #fff !important;
    }
    .form {
        // background: url(../../assets//main_bg.png);
        background: #f1f1f1;
        padding: 20px 50px;
        border-radius: 10px;
        h3 {
            text-align: center;
            color: #000 !important;
            margin-bottom: 10px;
        }
    }
}
.ant-col-16 {
    max-width: 100%;
}